import { NodeTypes, ProbeTypes, TSensorTypes } from "./generated_types";

/* eslint-disable camelcase */

export type STESubSensors = (
  TSensorTypes.STE |
  TSensorTypes.STE1 |
  TSensorTypes.STE2 |
  TSensorTypes.STE3 |
  TSensorTypes.STE4
);

export function isSTESubsensor(x: TSensorTypes): x is STESubSensors {
  // Yeah... we have to repeat all the members of STESubSensors
  // I haven't figured out a better way, or if one exists at all.
  const allSTESubSensors = [
    TSensorTypes.STE,
    TSensorTypes.STE1,
    TSensorTypes.STE2,
    TSensorTypes.STE3,
    TSensorTypes.STE4,
  ];
  return allSTESubSensors.includes(x);
}

export enum Materials {
  WOOD = "WOOD",
  PLAS = "PLAS",
  BRIC = "BRIC",
  CEME = "CEME",
  SAND = "SAND",
  CONC = "CONC",
}

export interface SelectedNodeSensors {
  [key: number]: Array<TSensorTypes>;
}

export interface Settings {
  id: number,
  installations: number,
  imi: number,
  imr: number,
  ims: number,
  cc4: number;
  imr_mk2: number;
  ims_mk2: number;
}
export interface AdminSettings extends Settings {
  alarm_delay: number;
  access_key: string;
  secret_key: string;
  first_interval: number;
  second_interval: number;
  change_after: number;
  watchdog_url: string;
  google_map_api_key: string;
  watchdog_time: string;
  wd_to: number;
  wd_from: number;
}

export interface SensorData {
  captured_datetime: string;
  distinct_chart_line: string;
  nd: string;
  node_id: string;
  node_name: string;
  sensor_type: TSensorTypes;
  value: string;
}

export interface IndividualSensorData {
  id: number;
  value: string;
  sensor_type: TSensorTypes;
  page_idx: number;
  captured_datetime: string;
}

export interface SensorColours {
  // From NodeData interface the variable distinct_chart_line
  // is used to identify the key value of this interface
  [key: string]: string | null;
}

export interface StringObj {
  // Interface is used for 2 purposes, for titles variable in code and DateTimeHash interface
  // Titles variable key's are gotten from NodeData interface distinct_chart_line variable
  // For DataTimeHash interface key values are set
  // using a string value and distinct_chart_line variable
  [key: string]: string;
}

export interface StringArrayObj {
  [key: string]: Array<string>;
}

export interface NumberArrayObj {
  [key: number]: Array<string>;
}

export interface DateTimeHash {
  // From NodeData interface variable captured_datetime
  // is used for the interface key values
  [key: string]: StringObj;
}

export interface Types {
  // Key values are used for identification
  // of SensorColours interface values
  [key: string]: number;
}

export interface ResponseRelayLabels {
  nodeId: number;
  relayId: number;
  relayLabel: string;
}

export interface NodeFields {
  front_end_name: string;
  sens_type: TSensorTypes;
  enabled: boolean;
  sensor_color: string;
  state_is_on: boolean | null; // Whether or not the `electricity` is ON for the node
  latest_data: string | null;
  label?: string;
}

export interface QuietTime {
  start_time: number;
  end_time: number;
  days: number;
  mask: number;
}

export interface QuietModeScheduleTime {
  start_time: number;
  end_time: number;
  days: number;
}

export enum ChartTypes {
  Normal = "Normal",
  Weekly = "Weekly",
  Hourly = "Hourly",
  Daily = "Daily",
  Monthly = "Monthly",
}

export enum InstallationPages {
  Active = "active",
  Archived = "archive",
}

export interface MatchParams {
  mode?: InstallationPages;
  page?: string;
  id?: string;
}

export interface Match {
  isExact: boolean;
  path: string;
  url: string;
  params: MatchParams;
}

export interface ReportQueryContainer {
  installation: string;
  chartParams: string;
  materials: {[i: string]: Materials};  // Map<string, Materials> as "plain Object"
}

export interface SearchParams {
  searchDeviceNameValue?: string;
  searchLastSeenOnlineValue?: string;
  searchUniqueIDValue?: string | number;
}

export interface EnergyData {
  end: number | null;
  name: string;
  start: number | null;
  total: number;
  type: NodeTypes;
}

export interface Coordinates {
  lon: number;
  lat: number;
}

export interface MaxMinRange {
  max_time: string;
  min_time: string;
}

export interface PageMeta {
  dev_end_per_page: number;
  dev_start_per_page: number;
  total_installations: number;
  total_pages: number;
}

export interface MapApiInfo {
  google_map_api_key: string;
}

export enum Roles {
  Admin = "Admin",
  CompanyUser = "CompanyUser",
  Supervisor = "Supervisor",
  User = "User",
}

interface BaseUser {
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  role: Roles;

  // Only exists for pre-created users
  id: number;
  last_login: string;
  date_joined: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
}

export interface ListUser extends BaseUser {
  company_name?: string;
};
export interface User extends BaseUser {
  company?: Company | null;
};


export interface SelectedInstallation {
  pk: number;
  name: string;
  unique_id: string;
}
export interface ChartConfig {
  secondaryAxis?: TSensorTypes;
  yMaxMain?: number;
  yMinMain?: number;
  yMaxSecond?: number;
  yMinSecond?: number;
}

export interface ChartGetParams {
  allData?: boolean;
  chartType?: ChartTypes;
  linearData?: boolean;
  nodes?: SelectedNodeSensors;
  x1?: Date;
  x2?: Date;
  timezone?: string;
  visibleDates?: boolean;
  secondaryAxis?: TSensorTypes;
  yMaxMain?: number;
  yMinMain?: number;
  yMaxSecond?: number;
  yMinSecond?: number;
}

export interface ChartGetParamsStatic {
  allData: boolean;
  chartType: ChartTypes;
  linearData: boolean;
  nodes: SelectedNodeSensors;
  x1: Date;
  x2: Date;
  visibleDates: boolean;
  timezone: string;
  secondaryAxis?: TSensorTypes;
  yMaxMain?: number;
  yMinMain?: number;
  yMaxSecond?: number;
  yMinSecond?: number;
}

export interface QueryNode {
  types: Array<string>;
  id: number;
}

export interface Device {
  device_id: string; // Unique ID
  pk: number; // DAtabase primary key
}

export interface BaseInfoInstallation {
  pk: number,
  name: string,
  device_id: string
}
// This is used for admin-panel:user-installations:user-view
export interface UserInstallationComposition {
  user: User,
  devices: Array<Device>,
  active: Array<BaseInfoInstallation>,
  archived: Array<BaseInfoInstallation>,
}

// This is used for admin-panel:user-installations:user-edit view
export interface UserInstallationCompositionSingle {
  devices: Array<Device>,
  active: Array<BaseInfoInstallation>,
  archived: Array<BaseInfoInstallation>,
}

export interface Company {
  pk: number,
  name: string,
  companyUser?: User | null,
}
// This is used for admin-panel:user-installations:company view
export interface CompanyInstallationComposition {
  company: Company,
  devices: Array<Device>,
}

export enum ConnectivityType {
  GSM = "GSM",
  ETHERNET = "ETH",
}

interface Connectivity_GSM {
  type: ConnectivityType.GSM;
  csq: number;  // Mandatory for GSM
}

interface Connectivity_ETH {
  type: ConnectivityType.ETHERNET;
  // No extra fields
}

export type Connectivity = Connectivity_GSM | Connectivity_ETH;

interface InstallationCommon {
  pk: number,
  device_fk: number,
  name: string,
  created_time: string,
  archived_time: string | null,
  last_online: string | null,
  coordinates?: Coordinates;
  version: InstallationVersions | null;
}

export interface DeviceListInstallation extends InstallationCommon {
  nodes: number;
  // Only exists for MK2
  connectivity?: Connectivity;
  on_backup_power?: boolean;
}

export interface EmissionsData {
  intensity: number;
  year: number;
  entity: string;
  url: string;
}

export interface InstallationInfo extends InstallationCommon {
  sampling_rate: number,
  co2?: EmissionsData,
}

export enum InstallationVersions {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
}

export enum InstallationSamplingRates {
  // In seconds
  RATE1 = 300,
  RATE2 = 900,
  RATE3 = 3600,
}

export interface DeviceListDevice {
  pk: number;
  device_id: string;
  created: string;
  // Following 3 fields always forced `null` when mode=archive
  // TODO: Remove when response formats are differentiated
  last_online: string | null;
  removed_time: string | null;
  latest_installation: number | null;
}

export interface RestartDeviceListDevice extends DeviceListDevice {
  last_forced_restart : string | null;
  is_restart_pending: boolean;
  installation_name?: string;
}

export interface DeviceListItem {
  device: DeviceListDevice;
  installation?: DeviceListInstallation;
}

export interface DeviceListItemExtended extends DeviceListItem {
  staticArchived?: Date;
  staticLastOnline?: Date;
  installationId: number;
  isActive: boolean;
  staticDisplayName: string;
  hasNodes: boolean;
  connectivity?: Connectivity;
}

export type SelectOption<T> = {
  value: T;
  label: string;
}


/* DeviceNode types */

// Common fields for all DeviceNode objects
interface DeviceNodeCommon {
  active: boolean;
  id: number;
  latest_data: string | null;
  local_id: string;
  sensor_name: string;
  fields: Array<NodeFields>;
  link: number;
  timezone: Array<String> | null;
  standard_timezone: string;
}

// Specific fields for specific DeviceNode type

// IMS
export interface DeviceNodeIMS extends DeviceNodeCommon {
  node_type: NodeTypes.IMS;
}

// IMR
export interface DeviceNodeIMR extends DeviceNodeCommon {
  node_type: NodeTypes.IMR;
  state_is_on: boolean;
}

// IMI
export interface DeviceNodeIMI extends DeviceNodeCommon {
  node_type: NodeTypes.IMI;
  water_bucket_full: boolean;
  red_alarm: boolean;
  quiet_times: Array<QuietTime>;
  state_is_on: boolean;
}

// IMRMk2
export interface DeviceNodeIMRMk2 extends DeviceNodeCommon {
  node_type: NodeTypes.IMRMk2
  quiet_times: Array<QuietTime>
}

// CC4
export interface DeviceNodeCC4 extends DeviceNodeCommon {
  node_type: NodeTypes.CC4;
  quiet_times: Array<QuietTime>;
}

// IMSMk2 - has a common type and one for each probe
export interface DeviceNodeIMSMk2 extends DeviceNodeCommon {
  node_type: NodeTypes.IMSMk2;
  probe_fault: boolean;
}

export interface DeviceNodeIMSMk2RH extends DeviceNodeIMSMk2 {
  probe_type: ProbeTypes.RH;
}

export interface DeviceNodeIMSMk2VOC extends DeviceNodeIMSMk2 {
  probe_type: ProbeTypes.VOC;
  calibrating: boolean;
  acclimatising: boolean;
}

export interface DeviceNodeIMSMk2OHM extends DeviceNodeIMSMk2 {
  probe_type: ProbeTypes.OHM;
  material: string;
}

// AD9
export interface DeviceNodeAD9 extends DeviceNodeCommon {
  node_type: NodeTypes.AD9;
  water_bucket_full: boolean;
  red_alarm: boolean;
  quiet_times: Array<QuietTime>;
  quiet_fan_modes: Array<QuietModeScheduleTime>;
  model: string;
  ohtr: boolean;
  fanc: boolean;
  cloud_control: boolean;
  state_is_on: boolean;
  omod: string;
}

// The DeviceNode type union
export type DeviceNode = DeviceNodeIMS
  | DeviceNodeIMR
  | DeviceNodeIMI
  | DeviceNodeIMRMk2
  | DeviceNodeCC4
  | DeviceNodeIMSMk2RH
  | DeviceNodeIMSMk2VOC
  | DeviceNodeIMSMk2OHM
  | DeviceNodeAD9

// Helper types to avoid listing all types for commonly prop types used often
export type ScheduleDeviceNode = DeviceNodeIMI
  | DeviceNodeAD9
  | DeviceNodeIMRMk2
  | DeviceNodeCC4