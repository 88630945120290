import React from "react";

import { useSpring, animated } from "react-spring";

interface Props {
  title: string;
  description: string;
  icon: string;
  fromButton: React.CSSProperties;
  toButton: React.CSSProperties;
  fromSlider: React.CSSProperties;
  toSlider: React.CSSProperties;
  toggleVisibility: () => void;
}

export function SideInstructionsVisual({
  description,
  fromButton,
  fromSlider,
  icon,
  title,
  toButton,
  toSlider,
  toggleVisibility,
}: Props): React.ReactElement {
  const buttonStyleSpring = useSpring({
    ...toButton,
    from: { ...fromButton },
  });
  const sliderStyleSpring = useSpring({
    ...toSlider,
    from: { ...fromSlider },
  });
  return (
    <animated.div style={sliderStyleSpring}>
      <animated.button
        style={buttonStyleSpring}
        type="button"
        id="pollSlider-buttons"
        onClick={toggleVisibility}
        className="pr-3 pl-0 mr-0"
      >
        <i
          id="steps-info-icon"
          className={`fa ${icon} fa-3x steps-icons`}
          aria-hidden="true"
        />
      </animated.button>
      <div className="instructionTitle">
        <h3 className="m-0">{title}</h3>
      </div>
      <div
        id="instruction-body"
        className="instructionBody"
        // FIXME: Is there a better way to do this?
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </animated.div>
  );
}
