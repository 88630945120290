import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useChartGetParams } from "../hooks/UseChartGetParams";
import { DisplayChart } from "./DisplayChart";
import { ChartTypes, DeviceNode, Materials } from "../types/types";
import { TSensorTypes } from "../types/generated_types";
import { Requester } from "../utils/Requester";
import { SpinnyThingy } from "../components/Misc/SpinnyThingy";

const chartLoading = false;
export function HeadlessReportPage(): React.ReactElement {
  // Get the materials map from GET parameters before the
  // useChartGetParams() mutilate it!
  const location = useLocation();
  const earlyGetParams = useRef(new URLSearchParams(location.search));
  const materials = earlyGetParams.current.get("materials");
  const materialMap: Map<string, Materials> | undefined = (materials !== null) ?
    new Map<string, Materials>(Object.entries(JSON.parse(materials))) : undefined;

  const [nodes, setNodes] = useState<Array<DeviceNode>>();
  const [noNodesAvailable, setNoNodesAvailable] = useState<boolean>(false);

  const params: { id: string | undefined } = useParams();
  const [getParams] = useChartGetParams();

  // Used for selecting the secondary valueAxis sensor type
  // and displaying types next to the chart
  const selSensorTypes = useMemo(() => {
    if (nodes === undefined) {
      return [];
    }
    const tempTypes: TSensorTypes[] = [];
    nodes.forEach((node) => {
      node.fields.forEach((sens) => {
        if (sens.enabled) {
          tempTypes.push(sens.sens_type);
        }
      });
    });
    return Array.from(new Set(tempTypes)); // Removes duplicates
  }, [nodes]);

  useEffect(() => {
    if (params.id !== undefined) {
      void Requester.getInstallNodes({
        installationID: Number(params.id),
      }).then((res) => {
        res.data.map((node) => {
          // Toggle the enabled sensors
          const newNode = node;
          if (Object.keys(getParams.nodes).includes(node.id.toString())) {
            const fields = node.fields.map((sensor) => ({
              ...sensor,
              enabled: getParams.nodes[node.id].includes(sensor.sens_type),
            }));
            newNode.fields = fields;
          }
          return newNode;
        });

        setNodes(res.data);
        setNoNodesAvailable(res.data.length === 0);
      });
    }
  }, [getParams.nodes, params.id]);

  if (!nodes) return <SpinnyThingy />;

  return (
      <div>
        <DisplayChart
          legend={true}
          selSensorTypes={selSensorTypes}
          secondaryAxis={getParams.secondaryAxis}
          style={{ width: "1280px", height: "720px" }}
          nodes={nodes}
          noNodesAvailable={noNodesAvailable}
          forcedTimezone={getParams.timezone}
          constantTime={getParams.linearData?.valueOf() || false}
          startDate={new Date(getParams.x1?.valueOf() || new Date())}
          endDate={new Date(getParams.x2?.valueOf() || new Date())}
          chartType={getParams.chartType || ChartTypes.Normal}
          loading={chartLoading}
          setLoading={() => { }}
          yMaxMain={getParams.yMaxMain}
          yMinMain={getParams.yMinMain}
          yMaxSecond={getParams.yMaxSecond}
          yMinSecond={getParams.yMinSecond}
          mcList={materialMap}
        />
      </div>
  );
}
