import React, { ReactElement } from "react";
import { Toaster } from "react-hot-toast";

import { ErrorBoundary } from "./components/Page/ErrorBoundary";
import { Footer } from "./components/Page/Footer";
import { Header } from "./components/Page/Header";
import { Main } from "./components/Page/Main";
import { UserContextProvider } from "./contexts/UserContext";

import "./utils/language-layer";

const EIGHT_SECONDS = 8000;

export function App(): ReactElement {
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: "",
          style: {
            marginTop: "8rem",
            marginRight: "3rem",
          },
          duration: EIGHT_SECONDS,
        }}
      />
      <UserContextProvider>
        <>
          <div id="content">
            <Header />
            <ErrorBoundary>
              <Main />
            </ErrorBoundary>
          </div>
          <Footer />
        </>
      </UserContextProvider>
    </>
  );
}
