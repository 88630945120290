import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { PageBaseWrapper } from "./PageBaseWrapper";
import { DashboardRoutes } from "./DashboardRoutes";
import { HeaderContextProvider } from "../../contexts/HeaderContextProvider";
import { useUser } from "../../contexts/UserContext";
import { SpinnyThingy } from "../Misc/SpinnyThingy";
import { LogInManager } from "../../containers/LogInManager";
import { SettingsContextProvider } from "../../contexts/SettingsContextProvider";

export function Main(): React.ReactElement {

  const [renderPage, setRenderPage] = useState<JSX.Element | null>();
  const userContext = useUser();

  useEffect(() => {
    if (userContext.user === null && userContext.fetchingUser) {
      setRenderPage(
        <div className="spinny-thingy-margin">
          <SpinnyThingy />
        </div>,
      );
    } else if (userContext.user === null) {
      setRenderPage(
        <>
          <Route path="" render={() => <LogInManager />} />
          <Route path="" render={() => <Redirect to="" />} />
        </>,
      );
    } else {
      setRenderPage(
        <HeaderContextProvider>
          <SettingsContextProvider>
            <PageBaseWrapper>
              <DashboardRoutes />
            </PageBaseWrapper>
          </SettingsContextProvider>
        </HeaderContextProvider>,
      );
    }
  }, [userContext.user, userContext.fetchingUser]);

  return <main>{renderPage}</main>;
}
