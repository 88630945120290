import axios, { AxiosError, AxiosResponse } from "axios";
import { 
  AlarmInstallation, 
  InstalAndAlarmPk, 
  InstalAndEmailPk, 
  NodeAndAlarmId,
} from "../components/Alarms/types";
import {
  InstallationResponse,
  PwrUsageRequest,
  PwrUsageResponse,
  ChartDataRequest,
  ChartDataResponse,
  IndividualSensorRequest,
  IndividualSensorResponse,
  InstallNameIdRequest,
  UpdateSensorColorRequest,
  UpdateSensorColorResponse,
  InstallMaxRangeRequest,
  InstallMaxRangeResponse,
  InstallNodesRequest,
  InstallNodesResponse,
  InfoContentRequest,
  InfoContestResponse,
  UpdateDeviceLocRequest,
  ChangeNameRequest,
  GenerateReportRequest,
  LoginUserRequest,
  UserResponse,
  UpdateUserNameSurnameRequest,
  UpdateUserPasswordRequest,
  MessageResponse,
  StatusEnum,
  ToggleNodeSensorPowerStateRequest,
  DashboardSettingsResponse,
  AlarmTypesResponse,
  InstalAndNodeAlarmsRequest,
  InstalAndNodeAlarmsResponse,
  EmailListResponse,
  CreateInstAlarmRequest,
  CreateInstAlarmResponse,
  CreatePasSensAlarmRequest,
  CreatePasSensAlarmResponse,
  CreateDataSensAlarmRequest,
  CreateDataSensAlarmResponse,
  ActivityStage2DataResponse,
  ActivityStage3DataRequest,
  ActivityStage3DataResponse,
  ActivityStage2DataRequest,
  SetUserAsSupervised,
  SetUserAsUnsupervised,
  GetSensorLogsRequest,
  GetInstalLogsRequest,
  GetSensorLogsResponse,
  GetInstalLogsResponse,
  InstallationInfoPatch,
  UpdateNodeNameRequest,
  UpdateNodeNameResponse,
  GetRelayLabelsRequest,
  GetRelayLabelsResponse,
  UpdateRelayLabelRequest,
  UpdateRelayLabelResponse,
  PostEmailRequest,
  MessageResponseData,
  ChangeEmailRequest,
  UpdateSensorMaterialRequest,
  UpdateSensorMaterialResponse,
} from "../types/requesterTypes";
import {
  AdminSettings,
  Company,
  Device,
  DeviceListDevice,
  DeviceListInstallation,
  InstallationPages,
  ListUser,
  Roles,
  SelectedNodeSensors,
  User,
  UserInstallationComposition,
  RestartDeviceListDevice,
  InstallationInfo,
  MapApiInfo,
  QuietTime,
  QuietModeScheduleTime,
} from "../types/types";
import { isMessageResponse } from "./utility";

const apiClient = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

const urls = {
  "update-relay-label": "/api/core/update_relay_label",
  "get-relay-labels": "/api/core/get_relay_labels",
  "get-pwr-usage-data": "/api/core/get_pwr_usage_data",
  "get-chart-data": "/api/core/get_chart_data",
  "get-individual-sensor": "/api/core/get_individual_sensor",
  "installation-name-device-id": "/api/core/installation-info",
  "update-node-name": "/api/core/update_node_name",
  "update-sensor-color": "/api/core/update_sensor_color",
  "update-sensor-material": "/api/core/update_sensor_material",
  "get-installation-max-range": "/api/core/get_installation_max_range",
  "get-installation-nodes": "/api/core/get-installation-nodes",
  "get-info-content": "/api/core/get_info_content",
  "get-installations-list": "/api/core/get_installations_list",
  "update-device-location": "/api/core/update-device-location",
  "change-name": "/api/core/change-name",
  "toggle-node-state": "/api/core/toggle-node-state",
  "default-chart": "/api/core/default-chart",
  "get-active-installations": "/api/reports/get-active-installations",
  "get-mk2-device-list": "/api/core/get_mk2_device_list",
  "generate-report": "/api/reports/generate-report",
  "get-map-api-key": "/api/core/get-map-api-key",
  "login-user": "/api/management/login-user",
  "logout-user": "/api/management/logout-user",
  "get-logged-in-user": "/api/management/get-logged-in-user",
  "post-user-name-surname": "/api/management/post-user-name-surname",
  "change-password": "/api/management/change-password",
  "set-csrf-token": "/api/management/set-csrf-token",
  users: "/api/management/users",
  companies: "/api/management/companies",
  "instal-node-data": "/api/alarms/instal_node_data",
  "get-settings": "/api/core/get-settings",
  "get-admin-settings": "/api/core/admin-settings",
  "alarm-types": "/api/alarms/alarm_types",
  "new-sensor-alarm": "/api/alarms/new_sensor_alarm",
  "get-inst-node-alarms": "/api/alarms/instal_node_alarms",
  "new-inst-alarm": "/api/alarms/bulk_installs",
  "new-pass-sensor-alarm": "/api/alarms/bulk_pas_sensor",
  "new-data-sensor-alarm": "/api/alarms/bulk_data_sensor",
  "crud-instal-alarm": "/api/alarms/installs",
  "crud-node-pas-alarm": "/api/alarms/passive",
  "crud-node-data-alarm": "/api/alarms/data",
  "crud-alarm-email": "/api/alarms/email",
  "update-email-list": "/api/alarms/update_email_list",
  "get-logs-sensor": "/api/alarms/logs/sensor",
  "get-logs-installation": "/api/alarms/logs/installation",
  "user-installations": "/api/management/admin-user-installations",
  "user-installations-edit": "/api/management/admin-user-installations/edit",
  company: "/api/management/company",
  activity: "/api/management/activity",
  "supervisors-in-company": "/api/management/support/supervisor",
  "unsupervised-in-company": "/api/management/support/unsupervised",
  "supervised-for-supervisor": "/api/management/support/supervised",
  languages: "/i18n/languages.json",
  "forced-restart-crud": "/api/core/forced-restart",
  "create-quiet-time": "/api/core/create_quiet_time",
  "get-installations-with-alarm-email-count": "/api/alarms/installations-with-alarm-and-email-count",
};

async function handleError<T>(err: AxiosError): Promise<T | undefined> {
  if (err.response) {
    if (
      isMessageResponse(err.response.data)
      && err.response.data.status === StatusEnum.authenticationErr
    ) {
      await post(urls["logout-user"], "");
    } else {
      return err.response.data as T;
    }
  }
  return undefined;
}

async function get<T, B>(url: string, params: B): Promise<T> {
  const response = await apiClient
    .get<T>(url, { params })
    .then((val) => val.data)
    .catch(async (err) => {
      const errData = await handleError<T>(err);
      if (errData) {
        return errData;
      }
      throw err;
    });
  return response;
}

async function post<T, B>(url: string, params: B): Promise<T> {
  const response = await apiClient
    .post<T>(url, params)
    .then((val) => val.data)
    .catch(async (err) => {
      const errData = await handleError<T>(err);
      if (errData) {
        return errData;
      }
      throw err;
    });
  return response;
}

async function del<T, B>(url: string, params: B): Promise<T> {
  const response = await apiClient
    .delete<T>(url, { params })
    .then((val) => val.data)
    .catch(async (err) => {
      const errData = await handleError<T>(err);
      if (errData) {
        return errData;
      }
      throw err;
    });
  return response;
}

async function patch<T, B>(url: string, params: B): Promise<T> {
  const response = await apiClient
    .patch<T>(url, params)
    .then((val) => val.data)
    .catch(async (err) => {
      const errData = await handleError<T>(err);
      if (errData) {
        return errData;
      }
      throw err;
    });
  return response;
}

async function put<T, B>(url: string, params: B): Promise<T> {
  const response = await apiClient
    .put<T>(url, params)
    .then((val) => val.data)
    .catch(async (err) => {
      const errData = await handleError<T>(err);
      if (errData) {
        return errData;
      }
      throw err;
    });
  return response;
}

async function putRaw<T, B>(url: string, params: B): Promise<AxiosResponse<T>> {
  const response = await apiClient.put<T>(url, params);
  return response;
}

async function download<T, B>(url: string, params: B): Promise<AxiosResponse<T>> {
  const response = await apiClient
    .post<T>(url, params, {
    responseType: "blob",
  });
  return response;
}

class Requester {
  cookieSet = false;

  async getCookie(): Promise<Requester> {
    const result = await apiClient.get(urls["set-csrf-token"]);
    if (result.status === 200) {
      this.cookieSet = true;
    }
    return this;
  }

  postQuietTimes = (params: {
    id: number;
    quiet_times: Array<QuietTime>;
  }): Promise<MessageResponse> => 
    post(urls["create-quiet-time"], params)

  getActiveInstallations = (): Promise<InstallationResponse> => 
    get(urls["get-active-installations"], "");

  getPwrUsageData = (params: PwrUsageRequest): Promise<PwrUsageResponse> =>
    get(urls["get-pwr-usage-data"], params);

  getChartDataCall = (params: ChartDataRequest): Promise<ChartDataResponse> =>
    get(urls["get-chart-data"], params);

  getIndividualSensor = (
    params: IndividualSensorRequest,
  ): Promise<IndividualSensorResponse> =>
    get(urls["get-individual-sensor"], params);

  getInstallationInfo = (
    params: InstallNameIdRequest,
  ): Promise<{
    devices: DeviceListDevice;
    installations: InstallationInfo;
  }> => get(urls["installation-name-device-id"], params);

  patchInstallationInfo = (params: InstallationInfoPatch): Promise<void> =>
    patch(urls["installation-name-device-id"], params);

  postToggleSensorPowerState = (
    params: ToggleNodeSensorPowerStateRequest,
  ): Promise<InstallationResponse | MessageResponse> =>
    post(urls["toggle-node-state"], params);

  postNodeName = (
    params: UpdateNodeNameRequest,
  ): Promise<UpdateNodeNameResponse | MessageResponse> =>
    post(urls["update-node-name"], params);

  postSensorColor = (
    params: UpdateSensorColorRequest,
  ): Promise<UpdateSensorColorResponse | MessageResponse> =>
    post(urls["update-sensor-color"], params);

  postSensorMaterial = (
    params: UpdateSensorMaterialRequest,
  ): Promise<UpdateSensorMaterialResponse> =>
    post(urls["update-sensor-material"], params);

  postRelayLabel = (
    params: UpdateRelayLabelRequest,
  ): Promise<UpdateRelayLabelResponse | MessageResponse> =>
    post(urls["update-relay-label"], params);

  getRelayLabels = (
    params: GetRelayLabelsRequest,
  ): Promise<GetRelayLabelsResponse | MessageResponse> =>
    post(urls["get-relay-labels"], params);

  getInstallMaxRange = (
    params: InstallMaxRangeRequest,
  ): Promise<InstallMaxRangeResponse> =>
    get(urls["get-installation-max-range"], params);

  getInstallNodes = (
    params: InstallNodesRequest,
  ): Promise<InstallNodesResponse> =>
    get(urls["get-installation-nodes"], params);

  getInfoContent = (
    params: InfoContentRequest,
  ): Promise<InfoContestResponse | MessageResponse> =>
    get(urls["get-info-content"], params);

  getInstallList = (
    mode: InstallationPages,
  ): Promise<{
    // The key is the Device PK
    devices: { [key: number]: DeviceListDevice };
    // The key is the Installation PK
    installations: { [key: number]: DeviceListInstallation };
  }> => get(urls["get-installations-list"], { mode });

  getMk2DeviceList = (
  ): Promise<{
    // The key is the Device PK
    devices: { [key: number]: RestartDeviceListDevice };
  }> => get(urls["get-mk2-device-list"], "");

  postUpdateDeviceLoc = (
    params: UpdateDeviceLocRequest,
  ): Promise<MessageResponse> => post(urls["update-device-location"], params);

  postChangeName = (params: ChangeNameRequest): Promise<MessageResponse> =>
    post(urls["change-name"], params);

  downloadGenerateReport = (
    params: GenerateReportRequest,
  ): Promise<AxiosResponse<Blob>> =>
    download(urls["generate-report"], params);

  getMapApiKey = (): Promise<MapApiInfo | MessageResponse> =>
    get(urls["get-map-api-key"], "");

  loginUser = (
    params: LoginUserRequest,
  ): Promise<UserResponse | MessageResponse> =>
    post(urls["login-user"], params);

  logoutUser = (): Promise<MessageResponse> => post(urls["logout-user"], "");

  getUser = (): Promise<UserResponse | MessageResponse> =>
    get(urls["get-logged-in-user"], "");

  updateUserNameSurname = (
    params: UpdateUserNameSurnameRequest,
  ): Promise<MessageResponse> => post(urls["post-user-name-surname"], params);

  updateUserPassword = (
    params: UpdateUserPasswordRequest,
  ): Promise<MessageResponse> => post(urls["change-password"], params);

  getAllUsers = (): Promise<{ data: Array<ListUser> }> => get(urls.users, {});

  getSingleUser = (pk: number): Promise<{ data: User }> =>
    get(`${urls.users}/${pk}`, {});

  deleteUser = (pk: number): Promise<void> => del(`${urls.users}/${pk}`, {});

  createUser = (user: {
    username: string;
    first_name: string;
    last_name: string;
    companyName: string;
    password: string;
    role: Roles;
    email: string;
  }): Promise<{ user?: User } | MessageResponse> => post(`${urls.users}`, user);

  updateUser = (
    user: {
      username: string;
      first_name: string;
      last_name: string;
      company: { companyName: string; pk?: number };
      password: { setNewPassword: boolean; newPassword: string };
      role: Roles;
      email: string;
    },
    pk: number,
  ): Promise<MessageResponse> => patch(`${urls.users}/${pk}`, user);

  getAllCompanies = (): Promise<{ data: Array<Company> }> =>
    get(urls.company, {});

  getDefaultSelectedSensors = (params: number): Promise<SelectedNodeSensors> =>
    get(urls["default-chart"], { installation_id: params });

  postDefaultSelectedSensors = (params: SelectedNodeSensors): Promise<void> =>
    post(urls["default-chart"], params);

  instalAndNodeAlarms = (
    params: InstalAndNodeAlarmsRequest,
  ): Promise<InstalAndNodeAlarmsResponse> =>
    get(`${urls["get-inst-node-alarms"]}/${params.pk}`, "");

  getDashboardSettings = (): Promise<DashboardSettingsResponse> =>
    get(urls["get-settings"], "");

  getAdminDashboardSettings = (): Promise<{ data: AdminSettings }> =>
    get(urls["get-admin-settings"], "");

  putAdminDashboardSettings = (data: AdminSettings): Promise<void> =>
    put(urls["get-admin-settings"], data);

  getAlarmTypes = (): Promise<AlarmTypesResponse> =>
    get(urls["alarm-types"], "");

  getAlarmsInstallations = (): Promise<{data: Array<AlarmInstallation>}> =>
    get(urls["get-installations-with-alarm-email-count"], "");
    
  createInstAlarm = (
    params: CreateInstAlarmRequest,
  ): Promise<CreateInstAlarmResponse | MessageResponse> =>
    post(urls["new-inst-alarm"], params);

  createPasSensAlarm = (
    params: CreatePasSensAlarmRequest,
  ): Promise<CreatePasSensAlarmResponse | MessageResponse> =>
    post(urls["new-pass-sensor-alarm"], params);

  createDataSensAlarm = (
    params: CreateDataSensAlarmRequest,
  ): Promise<CreateDataSensAlarmResponse> =>
    post(urls["new-data-sensor-alarm"], params);

  removeInstAlarm = (params: InstalAndAlarmPk): Promise<MessageResponse> =>
    del(`${urls["crud-instal-alarm"]}/${params.pk}/${params.alarm_id}`, "");

  removeNodeDataAlarm = (params: NodeAndAlarmId): Promise<MessageResponse> =>
    del(
      `${urls["crud-node-data-alarm"]}/${params.node_id}/${params.alarm_id}`,
      "",
    );

  removeNodePasAlarm = (params: NodeAndAlarmId): Promise<MessageResponse> =>
    del(
      `${urls["crud-node-pas-alarm"]}/${params.node_id}/${params.alarm_id}`,
      "",
    );

  getEmailList = (
    installation_pk: number,
  ): Promise<EmailListResponse> =>
    get(`${urls["crud-alarm-email"]}/${installation_pk}`, "");

  addAlarmEmail = (
    params: PostEmailRequest,
  ): Promise<MessageResponseData> => post(urls['crud-alarm-email'], params);

  changeAlarmEmail = (
    params: ChangeEmailRequest,
  ): Promise<MessageResponseData> => put(urls['crud-alarm-email'], params);

  removeAlarmEmail = (
    params: InstalAndEmailPk
  ): Promise<MessageResponse> =>
    del(`${urls['crud-alarm-email']}/${params.pk}/${params.email_id}`, '');

  getSensorLogs = (
    params: GetSensorLogsRequest,
  ): Promise<GetSensorLogsResponse> => get(
    `${urls["get-logs-sensor"]}/${params.installation_id}`,
    params,
  );

  createDeviceRestart = (
    device_id : string,
  ) : Promise<MessageResponse> => post(urls["forced-restart-crud"], device_id);

  cancelDeviceRestart = (
    device_id : string,
  ) : Promise<MessageResponse> => del(`${urls["forced-restart-crud"]}/${device_id}`, {});

  getInstalLogs = (
    params: GetInstalLogsRequest,
  ): Promise<GetInstalLogsResponse> => get(
    `${urls["get-logs-installation"]}/${params.installation_id}`,
    params,
  );

  getUserInstallations = (): Promise<{
    data: Array<UserInstallationComposition>;
  }> => get(urls["user-installations"], {});

  getSingleUserInstallations = (
    userId: number,
  ): Promise<{
    visibleData: Array<Device>;
    possibleData?: Array<Device>;
  }> => get(`${urls["user-installations-edit"]}/${userId}`, {});

  getCompany = (
    companyId: number,
  ): Promise<{
    company: Company;
  }> => get(`${urls.company}/${companyId}`, {});

  putUserDevicesEdit = (
    userPk: number,
    assignedDevices: Array<Device>,
  ): Promise<{ data: boolean }> =>
   putRaw(`${urls["user-installations-edit"]}/${userPk}`, { assignedDevices });

  getActivityStage2 = (
    query: ActivityStage2DataRequest,
  ): Promise<{
    data: Array<ActivityStage2DataResponse>;
  }> => post(`${urls.activity}/2`, query);

  getActivityStage3 = (
    query: ActivityStage3DataRequest,
  ): Promise<{ data: Array<ActivityStage3DataResponse> }> =>
    patch(`${urls.activity}/3`, query);

  getActivityStage3AsFile = (
    query: ActivityStage3DataRequest,
  ): Promise<AxiosResponse<string>> => download(`${urls.activity}/3`, query);

  getSupervisorsInACompany = (
    companyPk: number,
  ): Promise<{ data: Array<User> }> =>
    get(`${urls["supervisors-in-company"]}/${companyPk}`, {});

  getUnsupervisedInACompany = (
    companyPk: number,
  ): Promise<{ data: Array<User> }> =>
    get(`${urls["unsupervised-in-company"]}/${companyPk}`, {});

  getSupervisedForSupervisor = (
    supervisorPk: number,
  ): Promise<{ data: Array<User> }> =>
    get(`${urls["supervised-for-supervisor"]}/${supervisorPk}`, {});

  setUserAsSupervised = (params: SetUserAsSupervised): Promise<void> =>
    post(urls["supervised-for-supervisor"], params);

  setUserAsUnsupervised = (params: SetUserAsUnsupervised): Promise<void> =>
    post(urls["unsupervised-in-company"], params);

  getLanguageList = (): Promise<{ [key: string]: string }> =>
    get(urls.languages, {});

  setNodeControlConfig = (props:{
    ino_pk: number,
    ohtr: boolean,
    fanc: boolean,
  }) => post("/api/core/manage_installation_node_control", props);
  
  setCloudControl = (props: {
    ino_pk: number,
    cloud_control: boolean,
  }) => post("/api/core/toggle_cloud_control", props);

  setDehumidifierMode = (params: {
    id: number,
    hml: number | null,
    dew_point: boolean,
  }) => post("/api/core/set_dehumidifier_mode", params)

  getDehumidifierMode = (
    ino_pk: number
  ): Promise<{
    hml: number,
    prev_hml: number,
    dew_point: boolean,
  }> => get(`/api/core/get_dehumidifier_mode/${ino_pk}`, {})

  setQuietModeSchedule = (params: {
    id: number,
    quiet_fan_modes: Array<QuietModeScheduleTime>,
  }): Promise<MessageResponse> => post("/api/core/set_quiet_fan_mode", params)
}

const requester = new Requester();
/* NOTE: A slight refactoring of the Requester must be made, to ensure that there
are no race conditions because the cookie might not be set */
void requester.getCookie();

export { requester as Requester };
