import { Requester } from "../utils/Requester";
import { isMessageResponse } from "../utils/utility";

export async function fetchInstructions(
  location: string | null,
  setTitle: (arg: string) => void,
  setDescription: (arg: string) => void,
): Promise<void> {
  // TODO test if "" variable causes a bug
  const result = await Requester.getInfoContent({
    type: location !== null ? location : "",
  });
  if (!isMessageResponse(result)) {
    setTitle(result.title);
    setDescription(result.body);
  }
}

export function toggleVisibility(
  setVisible: (arg: boolean) => void,
  visible: boolean,
): void {
  setVisible(!visible);
}
