import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SideInstructionsVisual } from "../components/Misc/SideInstrucions/SideInstructionsVisual";
import { fetchInstructions, toggleVisibility } from "./SideInstructions.helper";

const buttonStyleNotVisible: React.CSSProperties = {
  zIndex: 1000,
  position: "fixed",
  width: "60px",
  height: "60px",
  right: "0vw",
  background: "#f8fbfc",
  border: " 1px solid rgba(208, 219, 220, 0.68)",
  borderRight: "0px",
};
const buttonStyleVisible = {
  ...buttonStyleNotVisible,
  right: "250px",
};

const infoSliderVisible: React.CSSProperties = {
  position: "fixed",
  height: "50%",
  background: "#f8fbfc",
  width: "250px",
  right: "0px",
  border: "1px solid rgba(208, 219, 220, 0.68)",
  zIndex: 1000,
  bottom: "0",
};

const infoSliderNotVisible = {
  ...infoSliderVisible,
  right: "-250px",
};

export function SideInstructions(): React.ReactElement {
  // TODO The backend API was not designed for multiple language support in mind!!!!
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [prevLocation, setPrevLocation] = useState<string>("");

  let type: string | null = null;
  if (location.pathname) {
    if (
      location.pathname.startsWith(
        "/dashboard/active/installation",
      )
    ) {
      type = "Active Sensor Data View";
    } else if (
      location.pathname.startsWith(
        "/dashboard/archive/installation",
      )
    ) {
      type = "Archive Sensor Data View";
    } else if (
      location.pathname.startsWith("/dashboard/list/active")
    ) {
      type = "Active List View";
    } else if (
      location.pathname.startsWith("/dashboard/list/archive")
    ) {
      type = "Archive List View";
    } else if (
      location.pathname.startsWith("/dashboard/list/archive")
    ) {
      type = "Archive Sensor Data View";
    }
  }

  useEffect(() => {
    if (type !== prevLocation) {
      fetchInstructions(type, setTitle, setDescription).catch((err) => console.log(err));
      setPrevLocation(type !== null ? type : "");
    }
  }, [type, prevLocation]);

  return (
    <SideInstructionsVisual
      title={title}
      description={description}
      icon={visible ? "fa-angle-double-right" : "fa-info-circle"}
      toButton={!visible ? buttonStyleNotVisible : buttonStyleVisible}
      fromButton={!visible ? buttonStyleVisible : buttonStyleNotVisible}
      fromSlider={!visible ? infoSliderVisible : infoSliderNotVisible}
      toSlider={!visible ? infoSliderNotVisible : infoSliderVisible}
      toggleVisibility={() => toggleVisibility(setVisible, visible)}
    />
  );
}
