import React, { createContext, useState } from "react";

interface Props {
  children: JSX.Element
}

export interface HeaderContextInterface {
  titleText: string,
  updateDocumentTitle: (arg: string) => void,
}

export const HeaderContext = createContext<HeaderContextInterface>(
  // A proxy object allows to completely define the behavior of a proxied object
  // In this case if a parent component wont be wrapped in HeaderContextProvider
  // Then any calls made to access the context values will result in
  // Being cached by the proxy object and display the errors bellow
  new Proxy({} as HeaderContextInterface, {
    apply: () => {
      throw new Error("You must wrap your component in an HeaderContextProvider");
    },
    get: () => {
      throw new Error("You must wrap your component in an HeaderContextProvider");
    },
  }),
);

function HeaderContextProvider({ children }: Props): React.ReactElement {
  const [titleText, setTitleText] = useState<string>("");
  return (
    <HeaderContext.Provider
      value={{
        titleText,
        updateDocumentTitle: (arg: string) => {
          document.title = arg;
          setTitleText(arg);
        },
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

const useHeader = (): HeaderContextInterface => React.useContext(HeaderContext);

export { HeaderContextProvider, useHeader };
